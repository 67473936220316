import * as React from 'react';

export interface IAppProps {
  title?: string;
}

export default function WhyUs(props: IAppProps) {
  return (
    <section id="why-us" className="why-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 d-flex align-items-stretch">
            <div className="content">
              <h3>Why Choose Us?</h3>
              <p>
                {process.env.REACT_APP_COMPANY} plays a decisive role in many social processes as well as quality health services.
                Throughout our corporate history, we have emphasized that health is a holistic process and that it is not enough to maintain
                well-being, and that it is also important to maintain well-being. 
              </p>
              {/* <div className="text-center">
                <a href="#" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-9 d-flex align-items-stretch">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-receipt"></i>
                    <h4>Our Expertise </h4>
                    <p>
                      Our reputation for outstanding clinical care and friendly atmosphere, together with a continuous investment in
                      technology and facilities ensures we attract leading consultants and specialists to work with us. 
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-cube-alt"></i>
                    <h4>Our Pioneering Technology </h4>
                    <p>
                      At {process.env.REACT_APP_COMPANY} we are committed to providing our patients with: advanced medical, surgical and
                      oncological treatments access to the latest drugs acquiring the best treatment technology available the latest digital
                      technology.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-images"></i>
                    <h4>Cost-efficient Quality Care</h4>
                    <p>
                      {process.env.REACT_APP_COMPANY} is committed to providing effective, high quality, cost-efficient healthcare in its
                      award winning hospitals and clinics. We provide care to both patients with insurance and those who wish to pay for
                      their own care.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
